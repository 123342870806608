import horizontal from "assets/images/brands/logo-sm.png";
import logo from "assets/images/brands/logo.png";

export const clientName = "New Era Capital Markets";
export const developedBy = "New Era Capital Markets";
export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_CP_DOMAIN;
export const companyName =  "New Era Capital Markets";
export const companyWebsite = "https://newera365.com/";
export const privacyPolicyLink = "https://newera365.com";
export const clientAgreementLink = "https://newera365.com";
export const LPOAAgreementLink = "https://newera365.com";
export const executionPolicy = "https://newera365.com";
export const conflictPolicy = "https://newera365.com";
export const disclosurePolicy = "https://newera365.com";
export const sidebarLogo = horizontal;
export const smLogo = logo;
export const mainLogo = logo;
export const lightLogo = logo;
